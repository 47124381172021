<template>
  <div class="database-home">
    <IndexHeader />
    <el-dialog
      width="25%"
      title="检索导出排序（可选）"
      class="add-form"
      :visible.sync="innerVisible"
    >
      <div class="dialog-btn-wrap">
        <el-button @click="export_data" type="primary" size="mini" class="commonBtn">
          确认导出
        </el-button>

        <el-button @click="innerVisible = false" type="primary" size="mini" class="commonBtn">
          取消
        </el-button>
      </div>
      <div class="form-wrap">
        字段有无是否转1：
        <el-switch v-model="yes2one" active-text="是"></el-switch>
      </div>
      <span style="padding: 20px; color: red">是、有、Y、YES转为1，否、无、N、NO转为0</span>
    </el-dialog>
    <div class="database-home-content" v-loading="loading">
      <transition name="el-fade-in-linear">
        <div class="header-info">
          <div class="header-left" v-if="queryInfoType == 'query'">
            <transition name="el-fade-in-linear">
              <div class="cardTitleContent" style="white-space: nowrap">
                <span class="cardTitle">{{ title }}</span>
              </div>
            </transition>
            <div class="btn">
              <el-button size="medium" type="primary" class="commonBtn" v-debounce="query_result">
                <i class="el-icon-search" />
                查询
              </el-button>
              <!-- 导出数据按钮 -->
              <el-button
                size="medium"
                type="primary"
                class="commonBtn"
                v-if="this.query_list.length >= 1"
                @click="export_data_select"
              >
                <i class="el-icon-s-promotion" />
                数据导出
              </el-button>
            </div>
          </div>
          <div v-else class="second-left">
            <transition name="el-fade-in-linear">
              <div class="cardTitleContent">
                <span class="cardTitle">{{ title }}</span>
              </div>
            </transition>
            <transition name="el-fade-in-linear">
              <div class="leftContent">
                <span class="infoNum">{{ allCount }}</span>
                <span class="infoTitle">患者数</span>
              </div>
            </transition>
            <transition name="el-fade-in-linear">
              <div class="leftContent">
                <span class="infoNum">{{ operationCount }}</span>
                <span class="infoTitle">手术数</span>
              </div>
            </transition>
            <transition name="el-fade-in-linear">
              <div class="leftContent">
                <span class="infoNum">{{ mCount }}</span>
                <span class="infoTitle">男</span>
              </div>
            </transition>
            <transition name="el-fade-in-linear">
              <div class="leftContent">
                <span class="infoNum">{{ wCount }}</span>
                <span class="infoTitle">女</span>
              </div>
            </transition>
          </div>
          <div class="second-right">
            <div class="rightContent">
              <el-button
                :type="queryInfoType == 'Month' ? 'primary' : 'text'"
                size="small"
                @click="chartIsShow('本月')"
              >
                本月
              </el-button>
            </div>

            <div class="rightContent">
              <el-button
                :type="queryInfoType == 'Year' ? 'primary' : 'text'"
                size="small"
                @click="chartIsShow('今年')"
              >
                今年
              </el-button>
            </div>

            <div class="rightContent">
              <el-button
                :type="queryInfoType == 'All' ? 'primary' : 'text'"
                size="small"
                @click="chartIsShow('全部')"
              >
                全部
              </el-button>
            </div>

            <!-- <div class="rightContent">
              <el-button type="primary" size="small" class="commonBtn" @click="queryIsShow">
                高级查询
              </el-button>
            </div> -->
          </div>
        </div>
      </transition>
      <transition name="el-fade-in-linear">
        <div v-show="chartShow" class="chartContent">
          <el-card :body-style="{ padding: '0.9375rem 1.25rem' }">
            <el-descriptions
              v-show="infoAllCountIsEmpty == true"
              :column="6"
              :colon="false"
              style="padding: 0 10px"
            >
              <el-descriptions-item v-for="(exam, examIndex) in examineList" :key="examIndex">
                <template slot="label">
                  <span class="examineListTitle">
                    {{ exam.DICTNAME }}
                    ({{ exam.NUM }}例)
                  </span>
                </template>
              </el-descriptions-item>
            </el-descriptions>
            <div>
              <transition name="el-zoom-in-top">
                <div class="chartBox" v-show="infoAllCountIsEmpty == true">
                  <div class="chartBoxItem">
                    <el-card
                      shadow="never"
                      class="chartInfoOpen"
                      :body-style="{ padding: '0.625rem 0.9375rem 0' }"
                    >
                      <div class="lineChart" id="lineChart"></div>
                    </el-card>
                  </div>
                  <div class="chartBoxItem">
                    <el-card
                      shadow="never"
                      class="chartInfo chart-main"
                      :body-style="{ padding: '1rem' }"
                    >
                      <div class="examineChart" id="examineChart"></div>
                    </el-card>
                    <el-card
                      shadow="never"
                      class="chartInfo chart-main"
                      :body-style="{ padding: '0.5rem' }"
                    >
                      <div class="searchList">
                        <div class="searchListItem">
                          <span class="searchLabel">主刀医生：</span>
                          <el-radio-group
                            size="mini"
                            v-model="queryExamineDoctorName"
                            v-for="(item, index) in queryExamineDoctorNameList"
                            :key="index"
                          >
                            <el-radio-button
                              :label="item"
                              style="margin: 0 5px 5px 0"
                              @click.native.prevent="
                                selectExamineDoctorNameRadio(queryExamineDoctorName, item)
                              "
                            ></el-radio-button>
                          </el-radio-group>
                        </div>
                        <div class="searchListItem">
                          <span class="searchLabel">手术名称：</span>
                          <el-radio-group
                            size="mini"
                            v-model="queryExamineOperationName"
                            v-for="(item, index) in queryExamineOperationNameList"
                            :key="index"
                          >
                            <el-radio-button
                              :label="item"
                              style="margin: 0 5px 5px 0"
                              @click.native.prevent="
                                selectExamineOperationNameRadio(queryExamineOperationName, item)
                              "
                            ></el-radio-button>
                          </el-radio-group>
                        </div>
                      </div>
                      <div class="chartTable">
                        <el-table
                          :data="examineDataList"
                          border
                          stripe
                          height="100%"
                          style="width: 100%; border-radius: 5px"
                        >
                          <el-table-column
                            prop="examineName"
                            align="center"
                            label="手术名称"
                            min-width="180"
                          ></el-table-column>
                          <el-table-column
                            prop="doctor"
                            align="center"
                            label="主刀医生"
                            width="160"
                          ></el-table-column>
                          <el-table-column
                            prop="num"
                            align="center"
                            label="手术次数"
                            width="120"
                          ></el-table-column>
                        </el-table>
                      </div>
                    </el-card>
                  </div>
                  <div class="chartBoxItem">
                    <el-card
                      shadow="never"
                      class="chartInfo chart-main mapPosition"
                      :body-style="{ padding: '0.625rem 0.9375rem 0' }"
                    >
                      <div class="areaChart" id="areaChart"></div>
                      <el-button
                        class="areaBack"
                        type="primary"
                        size="small"
                        @click="getReturnMap()"
                        v-show="returnMap"
                      >
                        返回
                      </el-button>
                      <div class="leftMapTitle">
                        <div
                          class="mapTitleItem"
                          v-for="(area, areaIndex) in areaList"
                          :key="areaIndex"
                        >
                          <span v-if="areaListShow === true">{{ area.CITY }}: {{ area.NUM }}</span>
                          <span v-else>{{ area.COUNTY }}: {{ area.NUM }}</span>
                        </div>
                        <span v-show="areaList.length > 0">其他省份: {{ otherAreaCount }}</span>
                      </div>
                    </el-card>
                    <el-card
                      shadow="never"
                      class="chartInfo chart-main"
                      :body-style="{ padding: '1rem' }"
                    >
                      <div class="searchList">
                        <div class="searchListItem">
                          <span class="searchLabel">手术名称：</span>
                          <el-radio-group
                            size="mini"
                            v-model="queryAreaOperationName"
                            v-for="(item, index) in queryAreaOperationNameList"
                            :key="index"
                          >
                            <el-radio-button
                              :label="item"
                              style="margin: 0 5px 5px 0"
                              @click.native.prevent="
                                selectAreaOperationNameRadio(queryAreaOperationName, item)
                              "
                            ></el-radio-button>
                          </el-radio-group>
                        </div>
                        <div class="searchListItem">
                          <span class="searchLabel">省份：</span>
                          <el-radio-group
                            size="mini"
                            v-model="queryAreaProvinceName"
                            v-for="(item, index) in queryAreaProvinceNameList"
                            :key="index"
                          >
                            <el-radio-button
                              :label="item"
                              style="margin: 0 5px 5px 0"
                              @click.native.prevent="
                                selectAreaProvinceNameRadio(queryAreaProvinceName, item)
                              "
                            ></el-radio-button>
                          </el-radio-group>
                        </div>
                        <div class="searchListItem">
                          <span class="searchLabel">城市：</span>
                          <el-radio-group
                            size="mini"
                            v-model="queryAreaCityName"
                            v-for="(item, index) in queryAreaCityNameList"
                            :key="index"
                          >
                            <el-radio-button
                              :label="item"
                              style="margin: 0 5px 5px 0"
                              @click.native.prevent="
                                selectAreaCityNameRadio(queryAreaCityName, item)
                              "
                            ></el-radio-button>
                          </el-radio-group>
                        </div>
                        <div class="searchListItem">
                          <span class="searchLabel">县/区：</span>
                          <el-radio-group
                            size="mini"
                            v-model="queryAreaCountyName"
                            v-for="(item, index) in queryAreaCountyNameList"
                            :key="index"
                          >
                            <el-radio-button
                              :label="item"
                              style="margin: 0 5px 5px 0"
                              @click.native.prevent="
                                selectAreaCountyNameRadio(queryAreaCountyName, item)
                              "
                            ></el-radio-button>
                          </el-radio-group>
                        </div>
                      </div>
                      <div class="chartTable">
                        <el-table
                          :data="areaDataList"
                          border
                          stripe
                          height="100%"
                          style="width: 100%; border-radius: 5px"
                        >
                          <el-table-column
                            prop="province"
                            align="center"
                            label="省份"
                            min-width="180"
                          ></el-table-column>
                          <el-table-column
                            prop="city"
                            align="center"
                            label="城市"
                            min-width="180"
                          ></el-table-column>
                          <el-table-column
                            prop="county"
                            align="center"
                            label="县/区"
                            min-width="180"
                          ></el-table-column>
                          <el-table-column
                            prop="examineName"
                            align="center"
                            label="手术名称"
                            min-width="180"
                          ></el-table-column>
                          <el-table-column
                            prop="num"
                            align="center"
                            label="手术次数"
                            width="120"
                          ></el-table-column>
                        </el-table>
                      </div>
                    </el-card>
                  </div>
                  <div class="chartBoxItem">
                    <el-card
                      shadow="never"
                      class="chartInfo chart-main"
                      :body-style="{ padding: '1rem' }"
                    >
                      <div class="frequencyChart" id="frequencyChart"></div>
                    </el-card>
                    <el-card
                      shadow="never"
                      class="chartInfo chart-main"
                      :body-style="{ padding: '1rem' }"
                    >
                      <div class="searchList">
                        <div class="searchListItem">
                          <span class="searchLabel">手术名称：</span>
                          <el-radio-group
                            size="mini"
                            v-model="queryFrequencyOperationName"
                            v-for="(item, index) in queryFrequencyOperationNameList"
                            :key="index"
                          >
                            <el-radio-button
                              :label="item"
                              style="margin: 0 5px 5px 0"
                              @click.native.prevent="
                                selectFrequencyOperationNameRadio(queryFrequencyOperationName, item)
                              "
                            ></el-radio-button>
                          </el-radio-group>
                        </div>
                        <div class="searchListItem">
                          <span class="searchLabel">频次：</span>
                          <el-radio-group
                            size="mini"
                            v-model="queryFrequencyName"
                            v-for="(item, index) in queryFrequencyNameList"
                            :key="index"
                          >
                            <el-radio-button
                              :label="item.value"
                              style="margin: 0 5px 5px 0"
                              @click.native.prevent="selectFrequencyRadio(queryFrequencyName, item)"
                            >
                              {{ item.name }}
                            </el-radio-button>
                          </el-radio-group>
                        </div>
                      </div>
                      <div class="chartTable">
                        <el-table
                          :data="frequencyDataList"
                          border
                          stripe
                          height="100%"
                          style="width: 100%; border-radius: 5px"
                        >
                          <el-table-column
                            prop="frequency"
                            align="center"
                            label="频次"
                            min-width="180"
                          ></el-table-column>
                          <el-table-column
                            prop="examineName"
                            align="center"
                            label="手术名称"
                            min-width="180"
                          ></el-table-column>
                          <el-table-column
                            prop="num"
                            align="center"
                            label="手术次数"
                            width="120"
                          ></el-table-column>
                        </el-table>
                      </div>
                    </el-card>
                  </div>
                  <div class="chartBoxItem">
                    <el-card
                      shadow="never"
                      class="chartInfo chart-main"
                      :body-style="{ padding: '1rem' }"
                    >
                      <div class="deathChart" id="deathChart"></div>
                    </el-card>
                    <el-card
                      shadow="never"
                      class="chartInfo chart-main"
                      :body-style="{ padding: '1rem' }"
                    >
                      <div class="searchList">
                        <div class="searchListItem">
                          <span class="searchLabel">手术名称：</span>
                          <el-radio-group
                            size="mini"
                            v-model="queryDeathOperationName"
                            v-for="(item, index) in queryDeathOperationNameList"
                            :key="index"
                          >
                            <el-radio-button
                              :label="item"
                              style="margin: 0 5px 5px 0"
                              @click.native.prevent="
                                selectDeathOperationNameRadio(queryDeathOperationName, item)
                              "
                            ></el-radio-button>
                          </el-radio-group>
                        </div>
                        <div class="searchListItem">
                          <span class="searchLabel">患者姓名：</span>
                          <el-radio-group
                            size="mini"
                            v-model="queryPatientName"
                            v-for="(item, index) in queryPatientNameList"
                            :key="index"
                          >
                            <el-radio-button
                              :label="item"
                              style="margin: 0 5px 5px 0"
                              @click.native.prevent="selectPatientRadio(queryPatientName, item)"
                            ></el-radio-button>
                          </el-radio-group>
                        </div>
                      </div>
                      <div class="chartTable">
                        <el-table
                          :data="deathDataList"
                          border
                          stripe
                          height="100%"
                          style="width: 100%; border-radius: 5px"
                        >
                          <el-table-column
                            prop="examineName"
                            align="center"
                            label="手术名称"
                            min-width="180"
                          ></el-table-column>
                          <el-table-column align="center" label="患者姓名" min-width="120">
                            <template slot-scope="scope">
                              <el-button
                                @click="checkDeathPatient(scope.row)"
                                type="text"
                                size="small"
                              >
                                {{ scope.row.name }}
                              </el-button>
                            </template>
                          </el-table-column>
                          <el-table-column
                            prop="deathDate"
                            align="center"
                            label="死亡时间"
                            width="160"
                          ></el-table-column>
                          <el-table-column
                            prop="deathCause"
                            align="center"
                            label="死亡原因"
                            min-width="180"
                          ></el-table-column>
                        </el-table>
                      </div>
                    </el-card>
                  </div>
                  <!-- 新增 -->
                  <div class="chartBoxItem">
                    <el-card
                      shadow="never"
                      class="chartInfo chart-main"
                      :body-style="{ padding: '1rem' }"
                    >
                      <div class="frequencyChart" id="windowChart"></div>
                    </el-card>
                    <el-card
                      shadow="never"
                      class="chartInfo chart-main"
                      :body-style="{ padding: '1rem' }"
                    >
                      <div class="searchList">
                        <div class="searchListItem">
                          <span class="searchLabel">窗期：</span>
                          <el-radio-group
                            size="mini"
                            v-model="queryFollowName"
                            v-for="(item, index) in followTypeList"
                            :key="index"
                          >
                            <el-radio-button
                              :label="item"
                              style="margin: 0 5px 5px 0"
                              @click.native.prevent="selectFollowTypeRadio(queryFollowName, item)"
                            ></el-radio-button>
                          </el-radio-group>
                        </div>
                        <div class="searchListItem">
                          <span class="searchLabel">不良事件：</span>
                          <el-radio-group
                            size="mini"
                            v-model="queryBadEventName"
                            v-for="(item, index) in badEventTypeList"
                            :key="index"
                          >
                            <el-radio-button
                              :label="item.title"
                              style="margin: 0 5px 5px 0"
                              @click.native.prevent="selectBadEventRadio(queryBadEventName, item)"
                            >
                              {{ item.title }}
                            </el-radio-button>
                          </el-radio-group>
                        </div>
                        <div class="searchListItem">
                          <span class="searchLabel">类型：</span>
                          <el-radio-group
                            size="mini"
                            v-model="queryBadEventChildName"
                            v-for="(item, index) in badEventChildrenList"
                            :key="index"
                          >
                            <el-radio-button
                              :label="item.title"
                              style="margin: 0 5px 5px 0"
                              @click.native.prevent="
                                selectBadEventChildRadio(queryBadEventChildName, item.title)
                              "
                            >
                              {{ item.title }}
                            </el-radio-button>
                          </el-radio-group>
                        </div>
                      </div>
                      <div class="chartTable">
                        <el-table
                          :data="windowDataList"
                          border
                          stripe
                          height="100%"
                          style="width: 100%; border-radius: 5px"
                        >
                          <el-table-column
                            prop="window"
                            align="center"
                            label="随访窗期"
                            width="150"
                          ></el-table-column>
                          <el-table-column
                            prop="patientName"
                            align="center"
                            label="患者"
                            min-width="80"
                          ></el-table-column>
                          <el-table-column
                            prop="adverse"
                            align="center"
                            label="不良事件"
                            min-width="180"
                          ></el-table-column>
                          <el-table-column
                            prop="adverseType"
                            align="center"
                            label="不良事件类型"
                            min-width="200"
                          ></el-table-column>
                        </el-table>
                      </div>
                    </el-card>
                  </div>
                  <div class="chartBoxItem">
                    <el-card
                      shadow="never"
                      class="chartInfo"
                      style="width: 100%"
                      :body-style="{ padding: '1rem' }"
                    >
                      <span class="recentlyTitle">最近手术一览</span>
                      <div class="recentlyTable">
                        <el-table
                          :data="recentlyList"
                          border
                          stripe
                          height="100%"
                          style="width: 100%; border-radius: 5px"
                        >
                          <el-table-column
                            prop="patientName"
                            align="center"
                            label="患者姓名"
                            width="220"
                          >
                            <template slot-scope="scope">
                              <el-button @click="checkPatient(scope.row)" type="text" size="small">
                                {{ scope.row.patientName }}
                              </el-button>
                            </template>
                          </el-table-column>
                          <el-table-column align="center" label="手术名称" min-width="220">
                            <template slot-scope="scope">
                              <el-tag
                                v-for="(item, index) in scope.row.examineName?.split('|')"
                                :key="index"
                                style="margin: 5px 5px 5px 0"
                                size="mini"
                                type="info"
                              >
                                {{ item }}
                              </el-tag>
                            </template>
                          </el-table-column>
                          <el-table-column
                            prop="examineDoctor"
                            align="center"
                            label="主刀医生"
                            width="220"
                          ></el-table-column>
                          <el-table-column align="center" label="手术时间" width="220">
                            <template slot-scope="scope">
                              <span>{{ getTimestamp(scope.row.studyDate) }}</span>
                            </template>
                          </el-table-column>
                        </el-table>
                      </div>
                    </el-card>
                  </div>
                </div>
              </transition>
              <el-empty
                class="allInfoIsEmpty"
                v-show="infoAllCountIsEmpty == false"
                description="暂无数据"
              ></el-empty>
            </div>
          </el-card>
        </div>
      </transition>
      <transition name="el-fade-in-linear">
        <div v-show="queryShow" class="queryContent">
          <el-card>
            <div style="margin-bottom: 50px" v-for="(item, index) in list" :key="index">
              <!-- 连接关系 -->
              <div style="margin-bottom: 20px">
                <span style="margin-right: 20px">连接关系</span>
                <el-select
                  v-model="item.connect_options.value"
                  style="margin-right: 20px"
                  clearable
                  v-if="index >= 1"
                >
                  <el-option
                    v-for="item1 in item.connect_options"
                    :key="item1.value"
                    :label="item1.label"
                    :value="item1.value"
                  ></el-option>
                </el-select>
                <el-input
                  v-model="item.select_item"
                  class="input-with-select"
                  style="width: 30%"
                  clearable
                  @clear="clearOptions(item)"
                  @focus="getOptions(item)"
                >
                  <el-select
                    v-model="item.Retrieval_field_grouping.nodeTitle"
                    slot="prepend"
                    placeholder="请选择分组"
                    @change="changeConnectOptions(item.Retrieval_field_grouping.nodeTitle)"
                  >
                    <el-option
                      v-for="item1 in Retrieval_field_grouping"
                      :key="item1.id"
                      :label="item1.title"
                      :value="item1.id"
                    ></el-option>
                  </el-select>
                </el-input>
              </div>

              <!-- 运算关系 -->
              <div style="margin-bottom: 20px; display: flex; align-items: center">
                <span style="margin-right: 20px">运算关系</span>
                <el-select v-model="item.relationship.value" style="margin-right: 20px" clearable>
                  <el-option
                    v-for="item1 in item.relationship"
                    :key="item1.value"
                    :label="item1.label"
                    :value="item1.value"
                  ></el-option>
                </el-select>
                <!-- 根据所选树节点类型去调用不同的组件 -->
                <!-- 下拉框-combodata-->
                <el-select
                  v-model="item.field_values.comboValue"
                  style="margin-left: 20px; width: 20%"
                  clearable
                  v-if="item.nodedata.selectItems"
                >
                  <el-option
                    v-for="(item1, idx) in item.field_values.itemsList"
                    :key="item1.id || idx"
                    :label="item1.title"
                    :value="item1.title"
                  ></el-option>
                </el-select>
                <!-- dateSelect -->
                <el-date-picker
                  v-model="item.field_values.comboValue"
                  type="datetime"
                  align="center"
                  placeholder="选择日期"
                  v-else-if="item.nodedata.datatype === 'date'"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  format="yyyy-MM-dd HH:mm"
                ></el-date-picker>
                <el-input
                  clearable
                  v-model="item.field_values.comboValue"
                  v-else-if="item.nodedata.datatype === 'string'"
                  style="margin-left: 20px; width: 20%"
                ></el-input>
                <el-input
                  clearable
                  type="number"
                  v-else-if="item.nodedata.datatype === 'number'"
                  v-model="item.field_values.comboValue"
                  style="margin-left: 20px; width: 20%"
                ></el-input>

                <el-button
                  v-if="index === list.length - 1 || list.length === 1"
                  size="mini"
                  type="primary"
                  class="commonBtn"
                  style="margin-left: 20px"
                  @click="addListItem"
                >
                  <i class="el-icon-plus" />
                  新增
                </el-button>
                <el-button
                  @click="delListItem"
                  size="mini"
                  type="danger"
                  class="commonBtn"
                  style="margin-left: 30px"
                  v-if="index > 0"
                >
                  删除
                </el-button>
              </div>

              <!-- 检索字段搜查的树 -->
              <el-dialog
                title="查询条件选择"
                :visible.sync="item.Query_conditions_Visible"
                width="50%"
              >
                <el-tree
                  :data="tree_list"
                  :props="item.defaultProps"
                  @current-change="
                    (val) => {
                      select_items(val, item)
                    }
                  "
                ></el-tree>
              </el-dialog>
            </div>

            <!-- 查询出得结果表格 -->
            <el-table
              border
              stripe
              :data="query_list"
              @sort-change="sortChange"
              :height="tableHeight"
              ref="multipleTable"
              :header-cell-class-name="handleHeaderClass"
            >
              <el-table-column type="index" width="45" align="center"></el-table-column>
              <el-table-column
                label="库号"
                align="center"
                width="150"
                prop="fileNumber"
                sortable="custom"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                label="患者姓名"
                align="center"
                width="120"
                prop="patientsName"
                sortable="custom"
              >
                <template slot-scope="scope">
                  <el-button @click="checkFollowPatient(scope.row)" type="text" size="small">
                    {{ scope.row.patientsName }}
                  </el-button>
                </template>
              </el-table-column>
              <el-table-column
                label="病案号"
                align="center"
                width="150"
                prop="patientsID"
                sortable="custom"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                label="性别"
                align="center"
                width="80"
                prop="patientsSex"
                sortable="custom"
              ></el-table-column>
              <el-table-column
                label="年龄"
                align="center"
                width="80"
                show-overflow-tooltip
                prop="patientsAge"
                sortable="custom"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.ageDays">
                    {{ calculateAgeFromDays(scope.row.ageDays) }}
                  </span>
                  <span v-else>
                    {{ scope.row.patientsAge ? scope.row.patientsAge + '岁' : '-' }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                label="联系方式"
                align="center"
                width="180"
                prop="patientsPhone"
                sortable="custom"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                label="手术名称"
                align="center"
                min-width="180"
                prop="examineName"
                sortable="custom"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <el-tag
                    v-for="(item, index) in scope.row.examineNameList"
                    :key="index"
                    style="margin-right: 5px"
                    size="small"
                    type="info"
                  >
                    {{ item }}
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column
                label="手术日期"
                align="center"
                width="160"
                prop="studyDate"
                sortable="custom"
              ></el-table-column>
              <el-table-column
                align="center"
                label="上次随访"
                min-width="200"
                prop="lastFollowStage"
                sortable="custom"
              >
                <template slot-scope="scope">
                  <div class="tableAnimationHeight">
                    <el-tag
                      type="info"
                      size="small"
                      style="margin-left: 7px"
                      v-show="scope.row.lastFollowStage"
                    >
                      {{ scope.row.lastFollowStage }}
                    </el-tag>
                    <el-tag
                      type="info"
                      size="small"
                      style="margin-left: 7px"
                      v-show="scope.row.lastFollowDate"
                    >
                      {{ formatDate(scope.row.lastFollowDate) }}
                    </el-tag>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                label="本次随访"
                min-width="200"
                prop="nextFollowStage"
                sortable="custom"
              >
                <template slot-scope="scope">
                  <div class="tableAnimationHeight">
                    <el-tag
                      type="info"
                      size="small"
                      style="margin-left: 7px"
                      v-show="scope.row.nextFollowStage"
                    >
                      {{ scope.row.nextFollowStage }}
                    </el-tag>
                    <el-tag
                      type="info"
                      size="small"
                      style="margin-left: 7px"
                      v-show="scope.row.nextFollowDate"
                    >
                      {{ formatDate(scope.row.nextFollowDate) }}
                    </el-tag>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="user_info.pagenum"
              :page-sizes="[20, 50, 100]"
              :page-size="user_info.pagesize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="user_info.total"
            ></el-pagination>
          </el-card>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import * as echarts from 'echarts'
import { SERVER_HOST } from '../../utils/globalVariable'
import IndexHeader from '../../components/IndexHeader.vue'
// 各省份对应的矢量数据地址
import { getProvinceMapInfo } from '../../utils/map-utils'
const { city } = require('province-city-china/data')
import { getTimestamp } from '../../utils/index'

export default {
  name: 'statisticalInfo',
  components: {
    IndexHeader
  },
  data() {
    return {
      loading: true,
      chartShow: true,
      queryShow: false,
      returnMap: false,
      areaListShow: true,
      infoAllCountIsEmpty: true,
      innerVisible: false,
      ordersList: [],
      queryInfoType: 'Month',
      title: '',
      allCount: '',
      mCount: '',
      operationCount: '',
      wCount: '',
      otherAreaCount: '',
      infoMapName: '',
      initialName: '',
      areaChart: '',
      mapInfo: '',
      mapJson: '',
      tableHeight: window.innerHeight - 320, // 表格动态高度
      screenHeight: window.innerHeight, // 内容区域高度
      examineList: [],
      monthList: [],
      frequencyList: [],
      // 新增
      windowInfo: null,
      windowList: [],
      windowDataList: [],
      followTypeList: [],
      queryFollowName: '',
      queryBadEventName: '',
      queryBadEventChildName: '',
      badEventChildrenList: [],
      badEventTypeList: [
        {
          title: '死亡',
          id: '1',
          childType: '死亡类型',
          children: [
            {
              title: '心源性死亡',
              id: '1-1'
            },
            {
              title: '非心源性死亡',
              id: '1-2'
            }
          ]
        },
        {
          title: '心肌梗死',
          id: '2',
          children: []
        },
        {
          title: '卒中',
          id: '3',
          childType: '卒中类型',
          children: [
            {
              title: '致残性卒中',
              id: '3-1'
            },
            {
              title: '非致残性卒中',
              id: '3-2'
            }
          ]
        },
        {
          title: '出血',
          id: '4',
          childType: '出血类型',
          children: [
            {
              title: '致命性出血',
              id: '4-1'
            },
            {
              title: '严重出血',
              id: '4-2'
            },
            {
              title: '轻微出血',
              id: '4-3'
            }
          ]
        }
      ],

      areaList: [],
      deathList: [],
      areaData: [],
      recentlyList: [], // 最近十台手术详情
      // 动态表格
      examineDataList: [],
      queryExamineDoctorNameList: [],
      queryExamineDoctorName: '',
      queryExamineOperationNameList: [],
      queryExamineOperationName: '',
      areaDataList: [],
      queryAreaOperationNameList: [],
      queryAreaOperationName: '',
      queryAreaProvinceNameList: [],
      queryAreaProvinceName: '',
      queryAreaCityNameList: [],
      queryAreaCityName: '',
      queryAreaCountyNameList: [],
      queryAreaCountyName: '',
      frequencyDataList: [],

      queryFrequencyNameList: [
        {
          name: '首次',
          value: 1
        },
        {
          name: '二次',
          value: 2
        },
        {
          name: '三次',
          value: 3
        },
        {
          name: '多次',
          value: 4
        }
      ],
      yes2one: false,
      queryFrequencyName: '',
      queryFrequencyOperationNameList: [],
      queryFrequencyOperationName: '',
      deathDataList: [],
      queryDeathOperationNameList: [],
      queryDeathOperationName: '',
      queryPatientNameList: [],
      queryPatientName: '',
      // 地图配置项
      option: {
        backgroundColor: 'transparent',
        title: {
          text: '地域'
        },
        //提示框组件
        tooltip: {
          show: true,
          //触发类型：数据项图形触发
          trigger: 'item',
          padding: 10,
          borderWidth: 1,
          borderColor: '#409eff',
          backgroundColor: 'rgba(255,255,255,0.4)',
          // textStyle: {
          //   color: '#000000',
          //   fontSize: 12,
          // },
          formatter: function (params) {
            if (params.value !== params.value) {
              params.value = 0
            }
            let context = `
                   <div>
                       <p style="line-height: 30px; font-weight: 600">${params.name}</p>
                       <p><span>地区数量 : </span><span>${params.value}</span></p>
                   </div>
                `
            return context
          }
        },
        visualMap: {
          show: false,
          min: 1,
          max: 1000,
          text: ['高', '低'],
          realtime: false,
          calculable: true,
          inRange: {
            color: ['lightskyblue', 'yellow', 'orangered']
          }
        },
        geo: {
          map: '浙江省',
          roam: false, // 一定要关闭拖拽
          zoom: 1.25,
          // center: [105, 36], // 调整地图位置
          label: {
            show: true, //省份名展示
            fontSize: '10',
            color: 'rgba(0,0,0,0.8)'
          }
        },
        series: [
          {
            type: 'map',
            map: '浙江省',
            geoIndex: 0, // 解决设置geo后地图重影问题
            zoom: 1.25, //这里是关键，一定要放在 series中
            // silent: false, //鼠标移入区域变色 如果设置为true则无法高亮
            // 鼠标移入高亮区域属性
            emphasis: {
              itemStyle: {
                show: true,
                areaColor: 'blue', //鼠标滑过区域颜色
                color: 'blue' //鼠标滑过区域颜色
              }
            },
            data: this.areaData
          }
        ]
      },

      //// 查询条件窗口
      //字段分组下拉框
      Retrieval_field_grouping: [],
      // 选中的字段
      query_condition: {
        moduleCode: '',
        nodes: []
      },
      list: [
        {
          connect_options: [
            {
              value: 'and',
              label: 'and'
            },
            {
              value: 'or',
              label: 'or'
            }
          ],
          select_item: '',
          Retrieval_field_grouping: {
            nodeTitle: ''
          },
          defaultProps: {
            children: 'child',
            label: 'title'
          },
          Query_conditions_Visible: false,
          relationship: [
            {
              value: 'LIKE',
              label: '包含'
            },
            {
              value: 'NOTLIKE',
              label: '不包含'
            },
            {
              value: 'EQ',
              label: '等于'
            },
            {
              value: 'NOTEQ',
              label: '不等于'
            },
            {
              value: 'GE',
              label: '>='
            },
            {
              value: 'LE',
              label: '<='
            },
            {
              value: 'GT',
              label: '>'
            },
            {
              value: 'LT',
              label: '<'
            }
          ],
          field_values: {},
          nodedata: {}
        }
      ],
      //查询结果
      query_list: [],
      tree_list: [],
      user_info: {
        pagesize: 20,
        pagenum: 1,
        total: 0
      }
    }
  },
  created() {
    if (this.$route.query?.title) {
      this.title = this.$route.query?.title
    }
  },
  watch: {
    // 监听screenHeight从而改变table的高度
    screenHeight(val) {
      this.screenHeight = val
      this.tableHeight = this.screenHeight - 320
    },
    queryInfoType: {
      handler(val) {
        if (val == 'query') {
          this.query_condition.moduleCode = this.$route.query.moduleCode
        }
      }
    }
  },
  methods: {
    getTimestamp,
    formatDate(v) {
      if (!v || typeof v !== 'string') {
        return ''
      }
      const dateRegex = /^\d{4}-\d{2}-\d{2}/
      if (!dateRegex.test(v)) {
        return ''
      }

      return v.substring(0, 10)
    },
    calculateAgeFromDays(days) {
      if (days) {
        const currentDate = new Date()
        const currentYear = currentDate.getFullYear()

        const birthDate = new Date(currentDate.getTime() - days * 24 * 60 * 60 * 1000)
        const birthYear = birthDate.getFullYear()

        let age = currentYear - birthYear

        // 检查生日是否已经过了，如果还没过，则减去一年
        if (currentDate < new Date(birthDate.setFullYear(currentYear))) {
          age--
        }

        if (age < 1) {
          return days + '天'
        } else if (age < 2) {
          const remainingDays = Math.floor((currentDate - birthDate) / (24 * 60 * 60 * 1000))
          return '1年零' + remainingDays + '天'
        } else {
          return age + '岁'
        }
      } else {
        return ''
      }
    },

    // 排序
    sortChange({ column, prop, order }) {
      if (column.sortable !== 'custom') {
        return
      }
      column.multiOrder = order
      this.handleOrderChange(column.property, column.multiOrder)
    },
    handleOrderChange(orderColumn, orderState) {
      if (orderState) {
        let flagIsHave = false
        this.ordersList.forEach((element) => {
          if (element.orderColumn === orderColumn) {
            element.orderState = orderState
            flagIsHave = true
          }
        })
        if (!flagIsHave) {
          this.ordersList.push({
            orderColumn: orderColumn,
            orderState: orderState
          })
        }
      } else {
        this.ordersList = this.ordersList.filter((element) => {
          return element.orderColumn !== orderColumn
        })
      }
      if (this.query_condition.nodes.length) {
        this.query_resultPost()
      }
    },
    handleHeaderClass({ column }) {
      this.ordersList.forEach((element) => {
        if (column.property === element.orderColumn) {
          column.order = element.orderState
        }
      })
      // column.order = column.multiOrder
    },

    checkDeathPatient(row) {
      const { href } = this.$router.resolve({
        path: `/Detail/BaseInfo?&operationId=${row.uuid}`
      })
      window.open(href, '_blank')
    },
    checkPatient(row) {
      const { href } = this.$router.resolve({
        path: `/Detail/BaseInfo?&operationId=${row.uuid}`
      })
      window.open(href, '_blank')
    },
    initBaseInfo() {
      if (this.$route.query?.moduleId && !this.$route.query?.type) {
        this.getCountPatientsByModule()
      } else {
        this.loading = false
        // this.queryIsShow()
      }
    },
    getCountPatientsByModule(type) {
      if (type == 'close') {
        this.examineList = []
        this.monthList = []
        this.frequencyList = []
        this.windowList = []
        this.areaList = []
        this.deathList = []
        this.recentlyList = []
        this.otherAreaCount = ''
      }
      if (this.$route.query?.moduleId) {
        let id = this.$route.query.moduleId
        let url =
          `/v1/webconsole/healthdb/countPatients/${this.$route.query.moduleCode}?` +
          (this.queryInfoType ? 'queryType=' + this.queryInfoType : '') +
          (id ? '&moduleId=' + id : '')
        this.$api.get(url).then(
          (res) => {
            if (res.data && res.data.status == 200) {
              this.loading = false
              this.allCount = res.data.data.allCount
              if (this.allCount == 0) {
                this.infoAllCountIsEmpty = false
                this.mCount = 0
                this.operationCount = 0
                this.wCount = 0
              } else {
                this.infoAllCountIsEmpty = true
                this.title = res.data.data.title
                this.mCount = res.data.data.mCount
                this.operationCount = res.data.data.operationCount
                this.wCount = res.data.data.wCount
                this.otherAreaCount = res.data.data.otherAreaCount
                this.infoMapName = res.data.data.province || '浙江省'
                this.initialName = res.data.data.province || '浙江省'
                if (res.data.data.recentlyList?.length > 0) {
                  res.data.data.recentlyList.forEach((item) => {
                    this.recentlyList.push({
                      ...item
                    })
                  })
                }
                if (res.data.data.examineList?.length > 0) {
                  this.getExamineTable('init')
                  res.data.data.examineList.forEach((item) => {
                    this.examineList.push({
                      ...item
                    })
                  })
                }
                if (res.data.data.monthList?.length > 0) {
                  res.data.data.monthList.forEach((item) => {
                    this.monthList.push({
                      ...item
                    })
                  })
                }
                if (res.data.data.frequencyList?.length > 0) {
                  this.getFrequencyTable('init')
                  res.data.data.frequencyList.forEach((item) => {
                    this.frequencyList.push({
                      ...item
                    })
                  })
                }
                // 新增
                if (res.data.data.windowList?.windows?.length > 0) {
                  this.getWindowTable('init')
                  this.windowInfo = res.data.data.windowList
                  res.data.data.windowList.windows.forEach((item) => {
                    this.windowList.push({
                      ...item
                    })
                  })
                }
                if (res.data.data.areaList?.length > 0) {
                  this.getAreaTable('init')
                  res.data.data.areaList.forEach((item) => {
                    this.areaList.push({
                      ...item
                    })
                  })
                }
                if (res.data.data.deathList?.length > 0) {
                  this.getDeathTable('init')
                  res.data.data.deathList.forEach((item) => {
                    this.deathList.push({
                      ...item
                    })
                  })
                }
                this.$nextTick(() => {
                  this.draw_mouthLine()
                  this.draw_areaChart()
                  this.draw_frequencyChart()
                  this.draw_windowChart()
                  this.draw_examineChart()
                  this.draw_deathChart()
                })
              }
            } else {
              this.loading = false
            }
          },
          () => {
            this.loading = false
          }
        )
      }
    },

    // 数据可视化
    chartIsShow(val) {
      if (val == '全部') {
        this.queryInfoType = 'All'
      }
      if (val == '今年') {
        this.queryInfoType = 'Year'
      }
      if (val == '本月') {
        this.queryInfoType = 'Month'
      }
      this.loading = true
      this.queryShow = false
      this.chartShow = true
      this.getCountPatientsByModule('close')
    },
    // queryIsShow() {
    // this.queryInfoType = 'query'
    // this.allCount = 0
    // this.mCount = 0
    // this.operationCount = 0
    // this.wCount = 0
    // this.chartShow = false
    // this.queryShow = true
    // this.get_Retrieval_field_grouping()
    // },
    selectExamineDoctorNameRadio(ele, item) {
      if (item === ele) {
        this.queryExamineDoctorName = ''
      } else {
        this.queryExamineDoctorName = item
      }
      this.getExamineTable()
    },
    selectExamineOperationNameRadio(ele, item) {
      if (item === ele) {
        this.queryExamineOperationName = ''
      } else {
        this.queryExamineOperationName = item
      }
      this.getExamineTable()
    },
    selectAreaOperationNameRadio(ele, item) {
      if (item === ele) {
        this.queryAreaOperationName = ''
      } else {
        this.queryAreaOperationName = item
      }
      this.getAreaTable()
    },
    selectAreaProvinceNameRadio(ele, item) {
      if (item === ele) {
        this.queryAreaProvinceName = ''
      } else {
        this.queryAreaProvinceName = item
      }
      this.getAreaTable()
    },
    selectAreaCityNameRadio(ele, item) {
      if (item === ele) {
        this.queryAreaCityName = ''
      } else {
        this.queryAreaCityName = item
      }
      this.getAreaTable()
    },
    selectAreaCountyNameRadio(ele, item) {
      if (item === ele) {
        this.queryAreaCountyName = ''
      } else {
        this.queryAreaCountyName = item
      }
      this.getAreaTable()
    },
    selectFrequencyOperationNameRadio(ele, item) {
      if (item === ele) {
        this.queryFrequencyOperationName = ''
      } else {
        this.queryFrequencyOperationName = item
      }
      this.getFrequencyTable()
    },
    selectFrequencyRadio(ele, item) {
      if (item.value === ele) {
        this.queryFrequencyName = ''
      } else {
        this.queryFrequencyName = item.value
      }
      this.getFrequencyTable()
    },
    selectDeathOperationNameRadio(ele, item) {
      if (item === ele) {
        this.queryDeathOperationName = ''
      } else {
        this.queryDeathOperationName = item
      }
      this.getDeathTable()
    },
    selectPatientRadio(ele, item) {
      if (item === ele) {
        this.queryPatientName = ''
      } else {
        this.queryPatientName = item
      }
      this.getDeathTable()
    },
    getAreaTable(type) {
      let params = {
        moduleId: this.$route.query.moduleId,
        queryType: this.queryInfoType || null,
        operationName: this.queryAreaOperationName || null,
        province: this.queryAreaProvinceName || null,
        city: this.queryAreaCityName || null,
        county: this.queryAreaCountyName || null
      }
      this.$api
        .get(`/v1/webconsole/healthdb/areaSummaryCount/${this.$route.query.moduleCode}`, params)
        .then((res) => {
          if (res.data && res.data.data) {
            this.areaDataList = res.data.data
          }
          if (type === 'init') {
            if (this.areaDataList.length > 0) {
              let arr = []
              let arr1 = []
              let arr2 = []
              let arr3 = []
              for (let i = 0; i < this.areaDataList.length; i++) {
                const element = this.areaDataList[i]
                if (element.examineName) {
                  arr.push(element.examineName)
                }
                if (element.province) {
                  arr1.push(element.province)
                }
                if (element.city) {
                  arr2.push(element.city)
                }
                if (element.county) {
                  arr3.push(element.county)
                }
              }
              this.queryAreaOperationNameList = [...new Set(arr)]
              this.queryAreaProvinceNameList = [...new Set(arr1)]
              this.queryAreaCityNameList = [...new Set(arr2)]
              this.queryAreaCountyNameList = [...new Set(arr3)]
            }
          }
        })
    },
    getDeathTable(type) {
      let params = {
        moduleId: this.$route.query.moduleId,
        queryType: this.queryInfoType || null,
        operationName: this.queryDeathOperationName || null,
        patientName: this.queryPatientName || null
      }
      this.$api
        .get(`/v1/webconsole/healthdb/deathSummaryCount/${this.$route.query.moduleCode}`, params)
        .then((res) => {
          if (res.data && res.data.data) {
            this.deathDataList = res.data.data
          }
          if (type === 'init') {
            if (this.deathDataList.length > 0) {
              let arr = []
              let arr1 = []
              for (let i = 0; i < this.deathDataList.length; i++) {
                const element = this.deathDataList[i]
                if (element.examineName) {
                  arr.push(element.examineName)
                }
                if (element.name) {
                  arr1.push(element.name)
                }
              }
              this.queryDeathOperationNameList = [...new Set(arr)]
              this.queryPatientNameList = [...new Set(arr1)]
            }
          }
        })
    },
    getFrequencyTable(type) {
      let params = {
        moduleId: this.$route.query.moduleId,
        queryType: this.queryInfoType || null,
        operationName: this.queryFrequencyOperationName || null,
        frequency: this.queryFrequencyName || null
      }
      this.$api
        .get(
          `/v1/webconsole/healthdb/frequencySummaryCount/${this.$route.query.moduleCode}`,
          params
        )
        .then((res) => {
          if (res.data && res.data.data) {
            this.frequencyDataList = res.data.data
          }
          if (type === 'init') {
            if (this.frequencyDataList.length > 0) {
              let arr = []
              for (let i = 0; i < this.frequencyDataList.length; i++) {
                const element = this.frequencyDataList[i]
                if (element.examineName) {
                  arr.push(element.examineName)
                }
              }
              this.queryFrequencyOperationNameList = [...new Set(arr)]
            }
          }
        })
    },
    selectFollowTypeRadio(ele, item) {
      if (item === ele) {
        this.queryFollowName = ''
      } else {
        this.queryFollowName = item
      }
      this.getWindowTable()
    },
    selectBadEventRadio(ele, item) {
      this.queryBadEventChildName = '' // 类型清空
      if (item.title === ele) {
        this.queryBadEventName = ''
      } else {
        this.queryBadEventName = item.title
        this.badEventChildrenList = item.children
      }
      this.getWindowTable()
    },
    selectBadEventChildRadio(ele, item) {
      if (item === ele) {
        this.queryBadEventChildName = ''
      } else {
        this.queryBadEventChildName = item
      }
      this.getWindowTable()
    },
    // 新增
    getWindowTable() {
      let params = {
        queryType: this.queryInfoType || null,
        windowTitle: this.queryFollowName,
        uiTitle: this.queryBadEventName,
        valueString: this.queryBadEventChildName
      }
      this.$api
        .get(
          `/v1/webconsole/healthdb/count/follow/window/poor/${this.$route.query.moduleCode}`,
          params
        )
        .then((res) => {
          if (res.data && res.data.data) {
            this.windowDataList = res.data.data
          }
        })
    },
    getExamineTable(type) {
      let params = {
        moduleId: this.$route.query.moduleId,
        queryType: this.queryInfoType || null,
        operationName: this.queryExamineOperationName || null,
        doctor: this.queryExamineDoctorName || null
      }
      this.$api
        .get(
          `/v1/webconsole/healthdb/operationSummaryCount/${this.$route.query.moduleCode}`,
          params
        )
        .then((res) => {
          if (res.data && res.data.data) {
            this.examineDataList = res.data.data
          }
          if (type === 'init') {
            if (this.examineDataList.length > 0) {
              let arr = []
              let arr1 = []
              for (let i = 0; i < this.examineDataList.length; i++) {
                const element = this.examineDataList[i]
                if (element.doctor) {
                  arr.push(element.doctor)
                }
                if (element.examineName) {
                  arr1.push(element.examineName)
                }
              }
              this.queryExamineDoctorNameList = [...new Set(arr)]
              this.queryExamineOperationNameList = [...new Set(arr1)]
            }
          }
        })
    },
    draw_examineChart() {
      let examineList = this.examineList
      var examineCharts = document.getElementById('examineChart')
      let pieData = []
      examineList.forEach((item) => {
        let nameNum = item.DICTNAME + '(' + item.NUM + ')'
        pieData.push({
          value: item.NUM,
          name: nameNum
        })
      })
      if (this.examineChart != null && this.examineChart != '' && this.examineChart != undefined) {
        this.examineChart.dispose() //销毁
      }
      this.examineChart = echarts.init(examineCharts) // 绘制图表
      var Option = {
        title: {
          text: '手术类别'
        },
        tooltip: {},
        legend: {
          // orient: 'vertical',
          type: 'scroll',
          orient: 'vertical',
          top: '20',
          left: '-5',
          data: pieData
        },
        series: [
          {
            type: 'pie',
            radius: '70%',
            center: ['55%', '50%'],
            data: pieData,
            // minAngle: 15, //最小角度
            startAngle: 270, //起始角度
            labelLine: {
              length: 5
            },
            label: {
              position: 'outer',
              alignTo: 'labelLine',
              fontSize: 12
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '16',
                fontWeight: 'bold'
              }
            }
          }
        ]
      }
      this.examineChart.setOption(Option)
    },
    draw_mouthLine() {
      let monthList = this.monthList
      var lineCharts = document.getElementById('lineChart')
      //循环元素
      let xAxisData = []
      let yAxisData = []
      let chartTitle = []
      monthList.forEach((item, index) => {
        let ele = []
        chartTitle.push(item.year)
        for (let j = 0; j < item.data.length; j++) {
          const element = item.data[j]
          if (xAxisData.length < 12) {
            xAxisData.push(element.month + '月')
          }
          ele.push(element.count)
        }
        yAxisData.push({
          name: chartTitle[index],
          type: 'line',
          data: ele
        })
      })
      if (this.lineChart != null && this.lineChart != '' && this.lineChart != undefined) {
        this.lineChart.dispose() //销毁
      }
      this.lineChart = echarts.init(lineCharts) // 绘制图表
      var Option = {
        title: {
          text: '手术量变化趋势'
        },
        tooltip: {
          trigger: 'axis'
        },
        toolbox: {
          feature: {
            magicType: { show: true, type: ['line', 'bar'] }
          }
        },
        legend: {},
        xAxis: {
          data: xAxisData
        },
        yAxis: {
          type: 'value',
          minInterval: 1
        },
        series: yAxisData
      }
      this.lineChart.setOption(Option)
    },
    draw_areaChart() {
      //  基于准备好的dom，初始化echarts实例
      this.areaChart = echarts.getInstanceByDom(document.getElementById('areaChart')) //有的话就获取已有echarts实例的DOM节点。
      if (this.areaChart == null || '') {
        // 如果不存在，就进行初始化。
        this.areaChart = echarts.init(document.getElementById('areaChart'))
      }
      let areaList = this.areaList
      this.mapInfo = getProvinceMapInfo(this.infoMapName)
      if (this.mapInfo?.key != undefined) {
        this.mapJson = require('../../assets/js/province/' + this.mapInfo?.key + '.json')
      }
      this.areaData = []
      areaList.forEach((item) => {
        this.areaData.push({
          value: item.NUM,
          name: item.CITY
        })
      })
      this.option.series[0].data = this.areaData
      this.option.series[0].map = this.infoMapName
      this.option.geo.map = this.infoMapName

      echarts.registerMap(this.infoMapName, this.mapJson)
      // 绘制图表
      this.areaChart.setOption(this.option)
      // 设置点击事件
      this.areaChart.on('click', (e) => {
        this.infoMapName = e.name
        if (e.componentSubType == 'map') {
          if (this.areaListShow == true) {
            this.getDownData(e.name, areaList)
          }
        }
      })
    },
    draw_deathChart() {
      let deathList = this.deathList
      var deathCharts = document.getElementById('deathChart')
      //循环元素
      let deathData = []
      deathList.forEach((item) => {
        let title = item.title + '(' + item.survival + '%' + ')'
        deathData.push({
          value: item.count,
          name: title
        })
      })
      if (this.deathChart != null && this.deathChart != '' && this.deathChart != undefined) {
        this.deathChart.dispose() //销毁
      }
      //初始化每一个图表
      this.deathChart = echarts.init(deathCharts) // 绘制图表
      var Option = {
        title: {
          text: '死亡率'
        },
        tooltip: {},
        legend: {
          orient: 'vertical',
          top: '20',
          left: '0',
          data: deathData
        },
        series: [
          {
            type: 'pie',
            radius: ['50%', '70%'],
            avoidLabelOverlap: false,
            data: deathData,
            label: {
              show: false,
              position: 'center'
            },
            labelLine: {
              show: false
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '20',
                fontWeight: 'bold'
              }
            }
          }
        ]
      }
      this.deathChart.setOption(Option)
    },
    draw_frequencyChart() {
      let frequencyList = this.frequencyList
      var frequencyCharts = document.getElementById('frequencyChart')
      //循环元素
      let frequencyData = []
      frequencyList.forEach((item) => {
        frequencyData.push({
          value: item.count,
          name: item.title
        })
      })

      if (
        this.frequencyChart != null &&
        this.frequencyChart != '' &&
        this.frequencyChart != undefined
      ) {
        this.frequencyChart.dispose() //销毁
      }
      //初始化每一个图表
      this.frequencyChart = echarts.init(frequencyCharts) // 绘制图表
      var Option = {
        title: {
          text: '手术频次'
        },
        tooltip: {},
        legend: {
          orient: 'vertical',
          top: '20',
          left: '0',
          data: frequencyData
        },
        series: [
          {
            type: 'pie',
            radius: ['50%', '70%'],
            avoidLabelOverlap: false,
            data: frequencyData,
            label: {
              show: false,
              position: 'center'
            },
            labelLine: {
              show: false
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '20',
                fontWeight: 'bold'
              }
            }
          }
        ]
      }
      this.frequencyChart.setOption(Option)
    },
    // 新增
    draw_windowChart() {
      let windowList = this.windowList
      var windowCharts = document.getElementById('windowChart')
      //循环元素
      let windowXAxisData = []
      let windowYAxisDeathData = [] // 死亡人数
      let windowYAxisApoplexyData = [] // 卒中
      let windowYAxisInfarctData = [] // 心肌梗死
      let windowYAxisBleedingData = [] // 出血
      let windowYAxisTotalData = [] // 随访人数

      const list = [
        { name: '死亡人数', value: this.windowInfo.deathNum },
        { name: '卒中', value: this.windowInfo.apoplexyNum },
        { name: '心肌梗死', value: this.windowInfo.infarctNum },
        { name: '出血', value: this.windowInfo.bleedingNum },
        { name: '随访人次', value: this.windowInfo.followNum }
      ]

      windowList.forEach((item) => {
        windowXAxisData.push(item.title)

        windowYAxisDeathData.push(item.death)
        windowYAxisApoplexyData.push(item.apoplexy)
        windowYAxisInfarctData.push(item.infarct)
        windowYAxisBleedingData.push(item.bleeding)
        windowYAxisTotalData.push(item.total)
      })
      this.followTypeList = [...new Set(windowXAxisData)]
      if (this.windowChart != null && this.windowChart != '' && this.windowChart != undefined) {
        this.windowChart.dispose() //销毁
      }
      //初始化每一个图表
      this.windowChart = echarts.init(windowCharts) // 绘制图表

      let option = {
        title: {
          text: '不良事件'
        },
        legend: {
          type: 'scroll',
          left: 80,
          formatter: function (name) {
            return name + '（' + list.find((item) => item.name === name).value + '人' + '）'
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        xAxis: {
          type: 'category',
          data: windowXAxisData,
          axisLabel: {
            //x轴文字的配置
            show: true,
            interval: 0, //使x轴文字显示全
            formatter: function (value, index) {
              const total = windowList.find((item) => item.title === value).total

              return `${value}\n（{a|${total}人}）`
            },
            rich: {
              a: {
                color: 'black',
                fontWeight: 600,
                lineHeight: 15
              }
            }
          }
        },
        yAxis: {
          type: 'value',
          minInterval: 1
        },
        series: [
          {
            data: windowYAxisTotalData,
            type: 'bar',
            name: '随访人次',
            barGap: 0
          },
          {
            data: windowYAxisDeathData,
            type: 'bar',
            name: '死亡人数',
            barGap: 0
          },
          {
            data: windowYAxisApoplexyData,
            type: 'bar',
            name: '卒中',
            barGap: 0
          },
          {
            data: windowYAxisInfarctData,
            type: 'bar',
            name: '心肌梗死',
            barGap: 0
          },
          {
            data: windowYAxisBleedingData,
            type: 'bar',
            name: '出血',
            barGap: 0
          }
        ]
      }
      this.windowChart.setOption(option)
    },
    async getDownData(name, areaList) {
      // 获取到下钻的json数据
      for (var l = 0; l < city.length; l++) {
        if (name === city[l].name) {
          let newMapJson = await this.getMapCityJson(city[l].code)
          // 注册新下钻数据的map
          echarts.registerMap(name, newMapJson)
          break
        }
      }
      // 把option中map设置为最新下钻的map名称
      this.option.series[0].map = name
      this.areaData = []
      areaList.forEach((item) => {
        this.areaData.push({
          value: item.NUM,
          name: item.COUNTY
        })
      })
      this.areaListShow = false
      this.returnMap = true
      this.option.series[0].data = this.areaData
      this.option.geo.map = name
      // 设置更改后的配置项
      this.areaChart.setOption(this.option)
    },
    async getMapCityJson(name) {
      let jsonData = await import('../../assets/js/citys/' + name + '.json')
      return jsonData
    },
    getReturnMap() {
      if (this.infoMapName == this.initialName) {
        return
      } else {
        this.infoMapName = this.initialName
        this.mapInfo = getProvinceMapInfo(this.initialName)
        if (this.mapInfo.key != undefined) {
          this.mapJson = require('../../assets/js/province/' + this.mapInfo.key + '.json')
        }
        this.returnMap = false
        // 注册默认全国地图的json
        echarts.registerMap(this.initialName, this.mapJson)
        // 绘制图表
        this.areaData = []
        this.areaList.forEach((item) => {
          this.areaData.push({
            value: item.NUM,
            name: item.CITY
          })
        })
        this.areaListShow = true
        this.option.series[0].data = this.areaData
        this.option.series[0].map = this.initialName
        this.option.geo.map = this.initialName
        this.areaChart.setOption(this.option)
      }
    },

    //// 高级搜索 相关函数
    //新增一组组合条件
    addListItem() {
      let isOk = false
      this.list.forEach((item) => {
        if (item.relationship.value && item.field_values.comboValue) {
          isOk = false
        } else {
          this.$message.error('请将查询关系填写完整')
          isOk = true
        }
      })
      if (!isOk) {
        var newvalue = {
          connect_options: [
            {
              value: 'and',
              label: 'and'
            },
            {
              value: 'or',
              label: 'or'
            }
          ],
          select_item: '',
          Retrieval_field_grouping: {
            nodeTitle: ''
          },
          defaultProps: {
            children: 'child',
            label: 'title'
          },
          Query_conditions_Visible: false,
          relationship: [
            {
              value: 'LIKE',
              label: '包含'
            },
            {
              value: 'NOTLIKE',
              label: '不包含'
            },
            {
              value: 'EQ',
              label: '等于'
            },
            {
              value: 'NOTEQ',
              label: '不等于'
            },
            {
              value: 'GE',
              label: '>='
            },
            {
              value: 'LE',
              label: '<='
            },
            {
              value: 'GT',
              label: '>'
            },
            {
              value: 'LT',
              label: '<'
            }
          ],
          field_values: {},
          nodedata: {}
        }
        this.list.push(newvalue)
      }
    },
    //删除一组组合条件
    delListItem() {
      var index = this.list.length - 1
      this.list.splice(index, 1)
      var index2 = this.query_condition.nodes.length - 1
      this.query_condition.nodes.splice(index2, 1)
      this.query_resultPost()
    },
    //获得检索字段下拉框信息
    async get_Retrieval_field_grouping() {
      const { data: res } = await this.$http.get(
        SERVER_HOST + `/v1/webconsole/search/root/${this.$route.query.moduleCode}`
      )
      if (res.status !== 200) return

      this.Retrieval_field_grouping = res.data
    },
    clearOptions(item) {
      item.nodedata = []
    },
    getOptions(item) {
      if (item.Retrieval_field_grouping.nodeTitle) {
        this.Query_conditions(item)
      } else {
        this.msg = this.$message({
          duration: 1000,
          type: 'error',
          message: '请先选择分组'
        })
      }
    },
    async changeConnectOptions(val) {
      if (val) {
        const { data: res } = await this.$http.get(
          SERVER_HOST + `/v1/webconsole/search/tree/${this.$route.query.moduleCode}/${val}`
        )
        this.get_tree_data(res.data)
      }
    },
    //检索字段组合树
    Query_conditions(item) {
      item.select_item = ''
      item.Query_conditions_Visible = true
    },
    // 获得所选分组的所有树节点
    get_tree_data(val) {
      this.tree_list = this.turnToTreeOfOneRootPlus(val.child)
    },
    // 建树
    turnToTreeOfOneRootPlus(arr) {
      if (arr) {
        for (let obj of arr) {
          obj['title'] = obj['data'].title
          if (obj['child']) {
            this.turnToTreeOfOneRootPlus(obj['child'])
          }
        }
        return arr
      }
    },
    //树选择节点函数
    select_items(data, item) {
      if (data.data.datatype !== 'Group') {
        item.Query_conditions_Visible = false
        item.nodedata = data.data
        item.select_item = data.title
        item.field_values.id = item.nodedata.id
        this.$set(item.field_values, 'comboValue', null)
        if (item.nodedata.selectItems) {
          this.$set(item.field_values, 'itemsList', item.nodedata.selectItems)
        }
      }
    },
    //查询函数
    async query_result() {
      this.query_condition.nodes = []
      let queryIsOk = true
      this.list.forEach((item) => {
        if (item.field_values.comboValue == undefined || item.field_values.comboValue == '') {
          this.$message.error('请将查询关系填写完整')
          queryIsOk = false
        } else {
          this.query_condition.nodes.push({
            connect: item.connect_options.value == undefined ? 'and' : item.connect_options.value,
            datatype: item.nodedata.datatype,
            group: item.nodedata.group,
            field: item.nodedata.field,
            operator: item.relationship.value,
            value: item.field_values.comboValue
          })
        }
      })
      if (queryIsOk) {
        this.query_resultPost()
      }
    },
    async query_resultPost() {
      let data = {
        and: [],
        or: [],
        orderString: ''
      }
      if (this.query_condition.nodes.length) {
        this.query_condition.nodes.forEach((item) => {
          if (item.connect === 'and' || !item.connect) {
            data.and.push({
              group: item.group,
              field: item.field,
              datatype: item.datatype,
              operator: item.operator,
              value: item.value
            })
          } else {
            data.or.push({
              group: item.group,
              field: item.field,
              datatype: item.datatype,
              operator: item.operator,
              value: item.value
            })
          }
        })
      }
      if (this.ordersList?.length) {
        this.ordersList.forEach((item) => {
          if (item.orderColumn === 'fileNumber') {
            if (item.orderState === 'descending') {
              data.orderString = data.orderString
                ? data.orderString + ',' + 'PubPatients.FILE_NUMBER desc'
                : 'PubPatients.FILE_NUMBER desc'
            } else {
              data.orderString = data.orderString
                ? data.orderString + ',' + 'PubPatients.FILE_NUMBER'
                : 'PubPatients.FILE_NUMBER'
            }
          }
          if (item.orderColumn === 'patientsName') {
            if (item.orderState === 'descending') {
              data.orderString = data.orderString
                ? data.orderString + ',' + 'PubPatients.PATIENTSNAME desc'
                : 'PubPatients.PATIENTSNAME desc'
            } else {
              data.orderString = data.orderString
                ? data.orderString + ',' + 'PubPatients.PATIENTSNAME'
                : 'PubPatients.PATIENTSNAME'
            }
          }
          if (item.orderColumn === 'patientsID') {
            if (item.orderState === 'descending') {
              data.orderString = data.orderString
                ? data.orderString + ',' + 'PubPatients.PATIENTSID desc'
                : 'PubPatients.PATIENTSID desc'
            } else {
              data.orderString = data.orderString
                ? data.orderString + ',' + 'PubPatients.PATIENTSID'
                : 'PubPatients.PATIENTSID'
            }
          }
          if (item.orderColumn === 'patientsAge') {
            if (item.orderState === 'descending') {
              data.orderString = data.orderString
                ? data.orderString + ',' + 'OPERATION.PATIENTSAGE desc'
                : 'OPERATION.PATIENTSAGE desc'
            } else {
              data.orderString = data.orderString
                ? data.orderString + ',' + 'OPERATION.PATIENTSAGE'
                : 'OPERATION.PATIENTSAGE'
            }
          }
          if (item.orderColumn === 'patientsSex') {
            if (item.orderState === 'descending') {
              data.orderString = data.orderString
                ? data.orderString + ',' + 'PubPatients.PATIENTSSEX desc'
                : 'PubPatients.PATIENTSSEX desc'
            } else {
              data.orderString = data.orderString
                ? data.orderString + ',' + 'PubPatients.PATIENTSSEX'
                : 'PubPatients.PATIENTSSEX'
            }
          }
          if (item.orderColumn === 'patientsPhone') {
            if (item.orderState === 'descending') {
              data.orderString = data.orderString
                ? data.orderString + ',' + 'PubPatients.PATIENTSPHONE desc'
                : 'PubPatients.PATIENTSPHONE desc'
            } else {
              data.orderString = data.orderString
                ? data.orderString + ',' + 'PubPatients.PATIENTSPHONE'
                : 'PubPatients.PATIENTSPHONE'
            }
          }
          if (item.orderColumn === 'examineName') {
            if (item.orderState === 'descending') {
              data.orderString = data.orderString
                ? data.orderString + ',' + 'OPERATION.EXAMINENAMES desc'
                : 'OPERATION.EXAMINENAMES desc'
            } else {
              data.orderString = data.orderString
                ? data.orderString + ',' + 'OPERATION.EXAMINENAMES'
                : 'OPERATION.EXAMINENAMES'
            }
          }
          if (item.orderColumn === 'studyDate') {
            if (item.orderState === 'descending') {
              data.orderString = data.orderString
                ? data.orderString + ',' + 'OPERATION.STUDYDATE desc'
                : 'OPERATION.STUDYDATE desc'
            } else {
              data.orderString = data.orderString
                ? data.orderString + ',' + 'OPERATION.STUDYDATE'
                : 'OPERATION.STUDYDATE'
            }
          }
          if (item.orderColumn === 'lastFollowStage') {
            if (item.orderState === 'descending') {
              data.orderString = data.orderString
                ? data.orderString + ',' + 'OPERATION.LAST_FOLLOW_DATE desc'
                : 'OPERATION.LAST_FOLLOW_DATE desc'
            } else {
              data.orderString = data.orderString
                ? data.orderString + ',' + 'OPERATION.LAST_FOLLOW_DATE'
                : 'OPERATION.LAST_FOLLOW_DATE'
            }
          }
          if (item.orderColumn === 'nextFollowStage') {
            if (item.orderState === 'descending') {
              data.orderString = data.orderString
                ? data.orderString + ',' + 'OPERATION.NEXT_FOLLOW_DATE desc'
                : 'OPERATION.NEXT_FOLLOW_DATE desc'
            } else {
              data.orderString = data.orderString
                ? data.orderString + ',' + 'OPERATION.NEXT_FOLLOW_DATE'
                : 'OPERATION.NEXT_FOLLOW_DATE'
            }
          }
        })
      }
      this.$api
        .post(
          `/v1/webconsole/search/query/operation/${this.$route.query.moduleCode}?current=${this.user_info.pagenum}&size=${this.user_info.pagesize}`,
          data
        )
        .then(
          (res) => {
            if (res.data.status !== 200)
              this.$message.error('未查询到相关信息'), (this.query_list = [])
            if (res.data && res.data.data) {
              this.query_list = res.data.data.records
              this.user_info.total = res.data.data.total
            } else {
              this.query_list = []
              this.user_info.total = 0
            }
          },
          (err) => {
            console.log(err)
          }
        )
    },
    //导出查询的数据为Excel表格
    export_data_select() {
      this.innerVisible = true
    },
    export_data() {
      this.loading = true
      if (this.innerVisible === true) {
        this.innerVisible = false
      }
      if (this.query_list.length > 0) {
        let data = {
          and: [],
          or: []
        }
        if (this.query_condition.nodes.length) {
          this.query_condition.nodes.forEach((item) => {
            if (item.connect === 'and' || !item.connect) {
              data.and.push({
                group: item.group,
                field: item.field,
                datatype: item.datatype,
                operator: item.operator,
                value: item.value
              })
            } else {
              data.or.push({
                group: item.group,
                field: item.field,
                datatype: item.datatype,
                operator: item.operator,
                value: item.value
              })
            }
          })
        }
        if (this.ordersList?.length) {
          this.ordersList.forEach((item) => {
            if (item.orderColumn === 'fileNumber') {
              if (item.orderState === 'descending') {
                data.orderString = data.orderString
                  ? data.orderString + ',' + 'PubPatients.FILE_NUMBER desc'
                  : 'PubPatients.FILE_NUMBER desc'
              } else {
                data.orderString = data.orderString
                  ? data.orderString + ',' + 'PubPatients.FILE_NUMBER'
                  : 'PubPatients.FILE_NUMBER'
              }
            }
            if (item.orderColumn === 'patientsName') {
              if (item.orderState === 'descending') {
                data.orderString = data.orderString
                  ? data.orderString + ',' + 'PubPatients.PATIENTSNAME desc'
                  : 'PubPatients.PATIENTSNAME desc'
              } else {
                data.orderString = data.orderString
                  ? data.orderString + ',' + 'PubPatients.PATIENTSNAME'
                  : 'PubPatients.PATIENTSNAME'
              }
            }
            if (item.orderColumn === 'patientsID') {
              if (item.orderState === 'descending') {
                data.orderString = data.orderString
                  ? data.orderString + ',' + 'PubPatients.PATIENTSID desc'
                  : 'PubPatients.PATIENTSID desc'
              } else {
                data.orderString = data.orderString
                  ? data.orderString + ',' + 'PubPatients.PATIENTSID'
                  : 'PubPatients.PATIENTSID'
              }
            }
            if (item.orderColumn === 'patientsAge') {
              if (item.orderState === 'descending') {
                data.orderString = data.orderString
                  ? data.orderString + ',' + 'OPERATION.PATIENTSAGE desc'
                  : 'OPERATION.PATIENTSAGE desc'
              } else {
                data.orderString = data.orderString
                  ? data.orderString + ',' + 'OPERATION.PATIENTSAGE'
                  : 'OPERATION.PATIENTSAGE'
              }
            }
            if (item.orderColumn === 'patientsSex') {
              if (item.orderState === 'descending') {
                data.orderString = data.orderString
                  ? data.orderString + ',' + 'PubPatients.PATIENTSSEX desc'
                  : 'PubPatients.PATIENTSSEX desc'
              } else {
                data.orderString = data.orderString
                  ? data.orderString + ',' + 'PubPatients.PATIENTSSEX'
                  : 'PubPatients.PATIENTSSEX'
              }
            }
            if (item.orderColumn === 'patientsPhone') {
              if (item.orderState === 'descending') {
                data.orderString = data.orderString
                  ? data.orderString + ',' + 'PubPatients.PATIENTSPHONE desc'
                  : 'PubPatients.PATIENTSPHONE desc'
              } else {
                data.orderString = data.orderString
                  ? data.orderString + ',' + 'PubPatients.PATIENTSPHONE'
                  : 'PubPatients.PATIENTSPHONE'
              }
            }
            if (item.orderColumn === 'examineName') {
              if (item.orderState === 'descending') {
                data.orderString = data.orderString
                  ? data.orderString + ',' + 'OPERATION.EXAMINENAMES desc'
                  : 'OPERATION.EXAMINENAMES desc'
              } else {
                data.orderString = data.orderString
                  ? data.orderString + ',' + 'OPERATION.EXAMINENAMES'
                  : 'OPERATION.EXAMINENAMES'
              }
            }
            if (item.orderColumn === 'studyDate') {
              if (item.orderState === 'descending') {
                data.orderString = data.orderString
                  ? data.orderString + ',' + 'OPERATION.STUDYDATE desc'
                  : 'OPERATION.STUDYDATE desc'
              } else {
                data.orderString = data.orderString
                  ? data.orderString + ',' + 'OPERATION.STUDYDATE'
                  : 'OPERATION.STUDYDATE'
              }
            }
            if (item.orderColumn === 'lastFollowStage') {
              if (item.orderState === 'descending') {
                data.orderString = data.orderString
                  ? data.orderString + ',' + 'OPERATION.LAST_FOLLOW_DATE desc'
                  : 'OPERATION.LAST_FOLLOW_DATE desc'
              } else {
                data.orderString = data.orderString
                  ? data.orderString + ',' + 'OPERATION.LAST_FOLLOW_DATE'
                  : 'OPERATION.LAST_FOLLOW_DATE'
              }
            }
            if (item.orderColumn === 'nextFollowStage') {
              if (item.orderState === 'descending') {
                data.orderString = data.orderString
                  ? data.orderString + ',' + 'OPERATION.NEXT_FOLLOW_DATE desc'
                  : 'OPERATION.NEXT_FOLLOW_DATE desc'
              } else {
                data.orderString = data.orderString
                  ? data.orderString + ',' + 'OPERATION.NEXT_FOLLOW_DATE'
                  : 'OPERATION.NEXT_FOLLOW_DATE'
              }
            }
          })
        }
        this.axios
          .post(
            `/v1/webconsole/search/export/operation/${this.$route.query.moduleCode}?yes2one=${
              this.yes2one === true ? 'true' : 'false'
            }`,
            data,
            {
              responseType: 'blob'
            }
          )
          .then((res) => {
            if (!res.headers['content-disposition']) {
              return this.$message.error('该模块未配置相关随访菜单')
            } else {
              this.loading = false
            }
            const { data, headers } = res
            let filename = ''
            if (headers['content-disposition']) {
              filename = headers['content-disposition'].split(';')[1].split('=')[1].split("8''")[1]
            } else {
              filename = '随访信息批量导出.xlsx'
            }
            // 此处当返回json文件时需要先对data进行JSON.stringify处理，其他类型文件不用做处理
            const blob = new Blob([data], { type: headers['content-type'] })
            let dom = document.createElement('a')
            let url = window.URL.createObjectURL(blob)
            dom.href = url
            dom.download = decodeURI(filename)
            dom.style.display = 'none'
            document.body.appendChild(dom)
            dom.click()
            dom.parentNode.removeChild(dom)
            window.URL.revokeObjectURL(url)
          })
      }
    },
    // 监听 pagesize 改变的事件
    handleSizeChange(newSize) {
      this.user_info.pagesize = newSize
      this.query_resultPost()
    },
    // 监听 页码值 改变的事件
    handleCurrentChange(newPage) {
      this.user_info.pagenum = newPage
      this.query_resultPost()
    },
    // 点击患者姓名
    checkFollowPatient(row) {
      row.detailType = 'specialized'
      const { href } = this.$router.resolve({
        path: `/follow/baseLine?${'moduleCode=' + this.$route.query.moduleCode}${
          row.moduleId ? '&moduleId=' + row.moduleId : ''
        }${
          row.operationId
            ? '&operationId=' + row.operationId
            : '' || row.uuid
            ? '&operationId=' + row.uuid
            : ''
        }${
          row.followId
            ? '&followId=' + row.followId
            : '' || row.nextFollowId
            ? '&followId=' + row.nextFollowId
            : ''
        }`
      })
      localStorage.setItem('patientInfo', JSON.stringify(row))
      window.open(href, '_blank')
    },
    setScrollBar() {
      var div = document.querySelector('.database-home-content')
      div.style.height = window.innerHeight - 62 + 'px'
      div.style.width = window.innerWidth + 'px'
    }
  },
  mounted() {
    this.setScrollBar()
    window.onresize = () => {
      return () => {
        // window.innerHeight:浏览器的可用高度
        window.screenHeight = window.innerHeight
        this.screenHeight = window.screenHeight
      }
    }
    // 浏览器缩放，更新ScrollBar位置
    window.addEventListener('resize', this.setScrollBar)
    setTimeout(() => {
      this.initBaseInfo()
    }, 200)
  }
}
</script>
<style scoped lang="less">
/deep/ .el-dialog__body {
  min-height: 300px;
  max-height: 600px;
  overflow: auto;
}
.el-input .el-select {
  width: 130px;
}
/deep/ .input-with-select {
  .el-input-group__prepend {
    .el-input__inner {
      color: #000;
      text-align: center;
    }
    // .el-input__inner::placeholder {
    //   // color: #000;
    //   text-align: center;
    // }
    // /* 谷歌 */
    // .el-input__inner::-webkit-input-placeholder {
    //   // color: #000;
    //   text-align: center;
    // }
    // /* 火狐 */
    // .el-input__inner:-moz-placeholder {
    //   // color: #000;
    //   text-align: center;
    // }
    // /*ie*/
    // .el-input__inner:-ms-input-placeholder {
    //   // color: #000;
    //   text-align: center;
    // }
  }
}

.add-form {
  /deep/ .el-dialog {
    background: #f8f8f8;

    .el-dialog__header {
      background: #2d507e;
      padding: 10px;
      .el-dialog__title {
        color: #fff;
      }
      .el-dialog__close {
        color: #fff;
      }
      .el-dialog__headerbtn {
        top: 0.9rem;
      }
    }

    .el-dialog__body {
      min-height: 200px;
      padding: 0;
      .pdfContent {
        height: 700px;
        padding: 0 4px;
        .pdfIframe {
          height: 690px;
          width: 100%;
        }
      }
      .dialog-btn-wrap {
        padding: 10px;
      }
    }
    .form-wrap {
      margin: 30px 0 20px;
      padding: 0 20px;
      .examinationItem {
        display: flex;
        align-items: center;
        .examinationItemTitle {
          width: 160px;
        }
        .examinationItemValue {
          height: 28px;
          line-height: 28px;
        }
      }
      .el-divider__text {
        background-color: #f8f8f8;
      }
      .el-row {
        margin-bottom: 15px;
      }
      .el-input {
        width: 250px;
      }
      .el-input__inner {
        border-color: #dcdfe6;
        height: 35px;
      }
      .el-tabs__header {
        position: fixed;
        z-index: 100;
        background: #fff;
      }
      .el-tabs__content {
        .container {
          margin-top: 80px;
        }
      }
    }
  }
}

.database-home-content::-webkit-scrollbar {
  // display: none;
  width: 12px;
}
.database-home-content::-webkit-scrollbar-track {
  border-radius: 10px;
}
.database-home-content::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
}
.database-home-content::-webkit-scrollbar-thumb:window-inactive {
  background: #ccc;
}
.database-home-content {
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  background-color: #f5f8fa;
  .header-info {
    background-color: #fff;
    display: flex;
    padding: 0 30px;
    margin-bottom: 5px;
    box-shadow: 0px 2px 2px #ccc;
    align-items: center;
    justify-content: space-between;
    .header-left {
      display: flex;
      align-items: center;
      justify-content: center;
      .cardTitleContent {
        width: 150px;
        padding: 5px 10px;
        margin: 10px 10px;
        .cardTitle {
          font-size: 20px;
          font-weight: 600;
          margin-left: 10px;
        }
      }
      .btn {
        display: flex;
        i {
          margin-right: 5px;
        }
      }
    }
    @media screen and (max-width: 1180px) {
      .header-left {
        width: 40%;
      }
    }
    .second-left {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      .cardTitleContent {
        width: 200px;
        padding: 5px 10px;
        margin: 10px 10px;
        .cardTitle {
          font-size: 20px;
          font-weight: 600;
          margin-left: 10px;
        }
      }
      .leftContent {
        width: 120px;
        padding: 5px 10px;
        margin: 10px 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 18px;
        font-weight: 600;
        border: 1px dashed #e4e6ef;
        border-radius: 5px;
        .infoNum {
          color: red;
        }
        .infoTitle {
          line-height: 1;
        }
      }
    }
    .second-right {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      .rightContent {
        width: 120px;
        padding: 10px 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 18px;
        font-weight: 600;
      }
    }
  }
  .chartContent {
    background-color: #f5f8fa;
    .cardTitle {
      font-size: 20px;
      font-weight: 600;
      margin-left: 10px;
    }
    .examineListTitle {
      font-size: 16px;
    }
    .clearfix:before,
    .clearfix:after {
      display: table;
      content: '';
    }
    .clearfix:after {
      clear: both;
    }
    .chartBox {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      .chartBoxItem {
        width: 100%;
        display: flex;
        .recentlyTitle {
          font-size: 18px;
          font-weight: bold;
        }
        .recentlyTable {
          margin-top: 5px;
          height: 15rem;
        }
        .chart-main {
          width: 50%;
        }
        .mapPosition {
          position: relative;
        }
        .chartInfo {
          .searchList {
            padding: 5px;
            .searchListItem {
              display: flex;
              align-items: center;
              flex-wrap: wrap;
              margin-bottom: 5px;
              .el-radio-group {
                /deep/ .el-radio-button__inner {
                  padding: 5px;
                }
              }
            }
            .searchLabel {
              height: 22px;
              white-space: nowrap;
            }
            .el-input {
              width: 150px;
              margin-right: 1rem;
            }
          }
          .chartTable {
            height: calc(270px - 3rem);
          }
          @media screen and (max-width: 1180px) {
            .chartTable {
              height: calc(270px - 5rem);
            }
          }
          .frequencyChart {
            width: 100%;
            height: 320px;
          }
          .examineChart {
            width: 100%;
            height: 320px;
          }
          .deathChart {
            width: 100%;
            height: 320px;
          }
          .areaChart {
            width: 100%;
            height: 320px;
          }
          .leftMapTitle {
            position: absolute;
            height: 235px;
            width: 100px;
            top: 47px;
            left: 2px;
            display: flex;
            flex-direction: column;
            .mapTitleItem {
              width: 88px;
              display: flex;
              flex-wrap: wrap;
            }
          }
          .areaBack {
            position: absolute;
            top: 0;
            right: 0;
          }
        }

        .chartInfoOpen {
          width: 100%;
          .lineChart {
            width: 100%;
            height: 320px;
          }
        }
      }
    }
    .allInfoIsEmpty {
      width: 100%;
      height: calc(100vh - 14rem);
    }
  }
  .queryContent {
    .el-card {
      height: calc(100vh - 8rem);
    }
    .el-pagination {
      text-align: center;
      margin: 5px 0;
    }
  }
}
</style>
